<template>
  <div class="basic_pp bottom_up" v-show="pp" :class="{ off: off }">
    <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
    <div class="content" style="background-color: #39ccd9" ref="basic_pp_body">
      <img
        src="@/assets/img/conpetti.png"
        width="100%"
        class="position-absolute top-0 start-0"
      />
      <div
        class="text-center pb-3 pt-5 text-white zindex-1"
        style="position: relative"
      >
        <h4 v-if="item_info.sender_code !== ''">
          {{ item_info.sender }}님에게 받은 선물
        </h4>
        <h4 v-if="item_info.sender_code === ''">
          {{ item_info.sender }}님에게 보낸 선물
        </h4>
        <small class="opacity-75">{{ send_date }}</small>
      </div>
      <div class="rounded-5 rounded-bottom-0 overflow-hidden shadow m-2">
        <div class="small p-3" style="background-color: #c4e2ff">
          <div class="text-center py-4 mb-2 position-relative">
            <img src="@/assets/img/gift7.png" alt="선물" width="60%" />
          </div>
          <div class="txt_box text-center fs-px-15 bg-body p-3 rounded">
            {{ item_info.sender_memo }}
          </div>
        </div>
        <div class="bg-body">
          <div class="fw-bold h6 p-3">행복한 라운딩 되세요!</div>
          <div class="cutting_line border-gray-relative-300 h-0 mx-3"></div>
          <div class="flex-between-center p-3">
            <div class="position-relative ratio ratio-1x1 w-40">
              <div
                class="img_thumbnail bg-gray-200 d-flex align-items-center justify-content-center"
              >
                <img
                  :src="item_info.coupon_image"
                  alt=""
                  width="100%"
                  class="shadow-sm"
                />
              </div>
            </div>
            <div class="p-2 w-60">
              <div class="fs-px-13 text-gray-relative-600">골프앤요트</div>
              <div class="text-truncate">{{ item_info.coupon_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block btn bg-white mt-4 thick mx-2" @click="pp_off()">
        확인
      </div>
    </div>

    <CancelPurchaseGift ref="CancelPurchaseGift" :item_info="item_info" />
  </div>
</template>

<script>
import CancelPurchaseGift from '@/components/popup/CancelPurchaseGift.vue';

const CryptoJS = require('crypto-js');
export default {
  components: {
    CancelPurchaseGift,
  },
  data() {
    return {
      gift_type: 0,

      msg: '골프 열심히 배워서 프로가 되길 바래!!',
      sender: '김연수',
      send_date: '2023-11-01',

      // front ui
      pp: false,
      off: false,
      timeout_id: null,
    };
  },
  props: {
    item_info: {
      type: Object,
      default: () => {
        return {
          img: '',
          name: '',
          price: 0,
        };
      },
    },
  },
  mounted() {},
  methods: {
    // front ui
    pp_off() {
      this.off = true;

      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;
        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.cutting_line {
  border-top: 1px dashed;
}
.bank_logo {
  width: 150px;
  margin: 0 auto;
  object-fit: cover;
  margin-bottom: 10px;
}
.basic_pp .content {
  max-height: 90vh;
}
.ratio-1x1 {
  --bs-aspect-ratio: 45% !important;
}
</style>
