<template>
  <!-- 취소팝업 -->
  <div v-show="pp">
    <div class="basic_pp bottom_up" v-show="pp" :class="{ off: off }">
      <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
      <div class="content bg-body overflow-hidden">
        <div
          class="header position-sticky flex-between-center py-2 text-truncate"
        >
          <h5>취소 사유를<br />선택해주세요.</h5>

          <i class="p-2 fal fa-times" @click="pp_off()"></i>
        </div>

        <div class="body overflow-auto pt-1" ref="basic_pp_body">
          <p class="mb-3 small text-gray-relative-600">
            취소 사유가 여러 개인 경우<br />대표적인 1개의 사유를 선택해주세요.
          </p>
          <div class="mt-2">
            <div
              class="p-3 round_checkbox border rounded-3 mb-2"
              v-for="(item, index) in [
                ' 단순 변심',
                ' 기상 악화',
                ' 결제요청 변경',
                ' 개인사정',
                ' 서비스 불만족',
              ]"
              :key="index"
            >
              <input
                type="radio"
                v-model="reason"
                :value="item"
                :id="'reason' + index"
              />
              <label :for="'reason' + index">
                <div class="chk_icon me-2"></div>
                <p class="conditions text-truncate">{{ item }}</p>
              </label>
            </div>
            <div class="p-3 round_checkbox border rounded-3">
              <input
                type="radio"
                v-model="reason"
                value="other"
                id="reason_other"
              />
              <label for="reason_other">
                <div class="chk_icon me-2"></div>
                <p class="conditions text-truncate">기타</p>
              </label>
            </div>
            <textarea
              v-show="reason === 'other'"
              class="w-100 border bg-body small rounded-2 p-2 mt-2 border-gray-relative-300"
              rows="3"
              v-model="reson_other"
              :disabled="reason != 'other'"
            ></textarea>
          </div>
          <div class="d-flex py-2 gap-1">
            <button
              class="btn btn-outline-main thick fs-px-14 w-100"
              @click="pp_off()"
            >
              닫기
            </button>
            <button
              class="btn btn-main thick fs-px-14 w-100 border-0"
              @click="Cancel()"
            >
              제출하기
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CryptoJS = require('crypto-js');

export default {
  components: {},
  props: {
    status: String,
  },
  data() {
    return {
      reason: '',
      reson_other: '',
      // front ui
      pp: false,
      off: false,
      code: this.$route.params.code,
      name: this.$store.state.name,
    };
  },
  methods: {
    Cancel() {
      if (this.status == 'P' || this.status == 'H') {
        this.CancelConfirm();
      }
      if (this.status == 'Y') {
        this.CancelReservationWait();
      }
    },
    async CancelReservationWait() {
      const confirm = await this.$confirm('결제 취소 요청하시겠습니까?');
      if (!confirm.is_ok) return;

      const reason = this.reason;
      const reason_other = this.reson_other;

      let cancel_memo = reason;
      if (reason == 'other') {
        cancel_memo = '기타 [' + reason_other + ']';
      }

      const code = this.code;
      const name = this.name;
      const body = { code, name, cancel_memo };

      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      const res = await this.$http.post(
        '/front/reservation/CancelReservationWait',
        {
          req,
        }
      );

      if (res.data.code == 200) {
        this.$alert(
          '정상적으로 취소 요청하였습니다.<br>결제 요청 내역에서 확인 가능합니다.'
        );
        this.$router.push('/mypage/reservation');
      } else if (res.data.code == 9999) {
        this.$store.dispatch('SETLOGOUT').then(() => {
          this.$router.push({ path: '/signin' });
        });
      }
      this.pp_off();
    },
    CancelConfirm() {
      const reason = this.reason;
      const reason_other = this.reson_other;

      let cancel_memo = reason;
      if (reason == 'other') {
        cancel_memo = '기타 [' + reason_other + ']';
      }

      const code = this.code;
      const body = { code, cancel_memo };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/front/reservation/CancelConfirm', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              this.$alert(
                '정상적으로 취소 처리되었습니다.<br>취소 내역에서 확인 가능합니다.'
              );
              this.pp_off();
              this.$router.push('/mypage/reservation');
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                this.$router.push({ path: '/signin' });
              });
            }
          }
        });
    },
    // front ui
    pp_off() {
      this.off = true;

      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;

        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.vc-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  background-color: transparent;
}
</style>
