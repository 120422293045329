<template>
  <div id="profile" class="bg-gray-relative-100">
    <div class="bg-body">
      <SubHeader title="프로필" :link="'/mypage'" :setting="true"></SubHeader>
    </div>
    <div class="avatar pt-5 position-absolute start-50 translate-middle-x">
      <div
        class="rounded-circle mx-auto position-relative"
        :style="{ backgroundImage: 'url(' + profile_path + ')' }"
      >
        <label
          for="profile_img_input"
          class="position-absolute badge rounded-circle bg-body end-0 bottom-0 shadow h-px-30 w-px-30"
        >
          <input
            type="file"
            hidden
            id="profile_img_input"
            accept="image/*;capture=camera"
            @change="ImageChange"
          />
          <i
            class="fas fa-camera text-dark position-absolute top-50 start-50 translate-middle"
          ></i>
        </label>
      </div>
    </div>
    <div class="p-3 pt-6">
      <div class="bg-body rounded-4 pt-6 shadow-300 p-3">
        <h6 class="text-center">{{ name }}</h6>
        <p class="text-gray-relative-500 text-center fs-px-14 mb-3">
          {{ email }}
        </p>

        <div class="input-group mb-1">
          <input
            type="text"
            id="name"
            class="form-control thick bg-body text-body border-gray-relative-200"
            v-model="name"
            placeholder="이름"
          />
          <!-- <button class="btn border border-start-0 border-gray-relative-200 text-body"><i class="fal fa-times"></i></button> -->
        </div>
        <form>
          <div class="input-group mb-3">
            <input
              type="password"
              id="password"
              class="form-control thick bg-body text-body border-gray-relative-200"
              placeholder="현재 비밀번호"
              v-model="current_pw"
              disabled
              autocomplete="none"
            />
            <span
              class="input-group-text fs-px-12"
              id="basic-addon2 w-px-100"
              @click="$router.push('/mypage/change-password')"
              >비밀번호 변경</span
            >
          </div>
          <!-- <div class="input-group mb-1">
                        <input type="password" id="new_password" class="form-control thick bg-body text-body border-gray-relative-200" placeholder="신규 비밀번호" v-model="new_pw" autocomplete="none">
                    </div>
                    <div class="input-group mb-1">
                        <input type="password" id="new_password2" class="form-control thick bg-body text-body border-gray-relative-200" placeholder="신규 비밀번호 확인" v-model="new_pw2" autocomplete="none">
                    </div> -->
        </form>
        <!-- <hr class="border-gray-relative-500"> -->
        <div class="input-group mb-1">
          <input
            type="tel"
            id="phone"
            class="form-control thick bg-body text-body border-gray-relative-200"
            v-model="phone"
            placeholder="휴대폰 번호"
            disabled
          />
          <span
            role="button"
            class="input-group-text fs-px-12"
            id="basic-addon2 w-px-100"
            v-if="phone_auth == 'Y'"
            @click="isEditPhone = !isEditPhone"
            >휴대폰 번호 변경</span
          >
          <span
            class="input-group-text fs-px-12"
            id="basic-addon2 w-px-100"
            v-if="phone_auth == 'N'"
            @click="NonPhoneAuth()"
            >미인증회원</span
          >
        </div>
        <div class="form-group mt-3 mb-3" v-if="isEditPhone">
          <label class="small text-gray-relative-600">휴대폰 번호</label>
          <div class="input-group">
            <input
              class="form-control thick border-gray-relative-300 bg-body text-body"
              type="tel"
              placeholder="휴대폰 번호 '-'없이 입력"
              v-model="contact"
              :disabled="disabled_phone"
            />
            <div
              class="certi_btn btn border flex-shrink-0 small w-px-40 text-gray-relative-600 border-gray-relative-300"
              @click="CheckDuplPhone()"
              v-if="phone_certifi_chk == null"
            >
              인증
            </div>
            <div
              class="certi_btn btn border flex-shrink-0 small w-px-40 text-white bg-danger border-danger"
              v-if="phone_certifi_chk == true"
            >
              인증완료
            </div>
          </div>
        </div>
        <div class="form-group mt-3 mb-3" v-if="phone_certifi_send_chk">
          <label class="small text-gray-relative-600 border-gray-relative-300"
            >인증번호</label
          >
          <div class="input-group">
            <div
              class="form-control border-gray-relative-300 bg-body text-body flex-between-center"
            >
              <input
                class="form-control border-0 outline-0 p-0"
                type="number"
                required
                placeholder="인증번호를 입력해주세요."
                v-model="certifi_phone_num"
              />
              <small
                class="countdown text-primary fw-normal flex-shrink-0"
                v-if="phone_certifi_send_chk"
                >{{ ShowTime(secu_count_num) }}</small
              >
            </div>
            <div
              class="certi_btn btn border flex-shrink-0 small text-gray-relative-600 border-gray-relative-300"
              v-if="!phone_certifi_chk"
              @click="CertificatePhone()"
            >
              인증확인
            </div>
            <div
              class="btn thick border px-3 flex-shrink-0 small text-primary border-gray-relative-300"
              v-if="phone_certifi_chk"
              @click="CertificatePhone()"
            >
              <i class="far fa-check"></i>
            </div>
          </div>
          <div
            class="small feedback text-gray-relative-600"
            v-if="phone_certifi_send_chk && phone_certifi_chk === null"
          >
            <small>- 휴대폰으로 발송된 인증번호를 입력해주세요.</small>
          </div>
          <div
            class="small feedback text-danger"
            v-if="phone_certifi_chk === false"
          >
            <small>- 인증번호가 틀렸습니다. 다시 입력해 주십시오.</small>
          </div>
          <div
            class="small feedback text-succsess"
            v-if="phone_certifi_chk === true"
          >
            <small>- 인증이 완료되었습니다.</small>
          </div>
        </div>
        <!-- <hr class="border-gray-relative-500"> -->
        <div class="input-group mb-1">
          <input
            type="text"
            id="address"
            class="form-control thick bg-body text-body border-gray-relative-200"
            v-model="addr1"
            placeholder="주소"
            disabled
          />
          <span
            class="input-group-text fs-px-12"
            id="basic-addon2 w-px-100"
            @click="SearchAddress()"
            v-if="show_area == false"
            ><i class="far fa-search"></i
          ></span>
          <span
            class="input-group-text fs-px-12"
            id="basic-addon2 w-px-100"
            @click="closeDaumPostcode()"
            v-if="show_area == true"
            ><i class="fal fa-times"></i
          ></span>
          <!-- <span class="input-group-text fs-px-12" id="basic-addon2 bg-success">주소 찾기</span> -->
        </div>
        <div class="input-group mb-1">
          <input
            type="text"
            id="detail"
            class="form-control thick bg-body text-body border-gray-relative-200"
            placeholder="상세 주소"
            v-model="addr2"
          />
        </div>
        <div class="input-group mt-1" :class="CheckShowAreaClass()">
          <div id="layer" class="col-12" :style="ShowArea()"></div>
        </div>

        <button
          class="btn thick bg-secondary text-white mt-2 w-100"
          @click="ChangeProfile()"
        >
          변경
        </button>
      </div>
    </div>
    <div class="px-4 py-3 text-gray-relative-500 fs-px-13 text-end">
      <span @click="LogOut()">로그아웃</span>
      <span class="text-gray-relative-300 mx-3 text-opacity-50">|</span>
      <router-link to="/opt-out">회원탈퇴</router-link>
    </div>
    <LoginCheck />
    <Phone ref="phonePopup"></Phone>
    <Loading v-if="loading" />
  </div>
</template>
<script>
import Phone from '@/components/mypage/PhoneComponent.vue';
import SubHeader from '@/components/common/SubHeader.vue';
import Loading from '@/components/common/Loading.vue';
import AWS from 'aws-sdk';
const urlencode = require('urlencode');

const CryptoJS = require('crypto-js');
// import myUpload from 'vue-image-crop-upload/upload-2.vue';
import LoginCheck from '@/components/common/LoginCheck.vue';
export default {
  components: {
    SubHeader,
    LoginCheck,
    Loading,
    Phone,
    // ,'my-upload': myUpload
  },
  data() {
    return {
      loading: false,
      name: '',
      email: '',
      phone: '',
      phone_auth: '',
      addr1: '',
      addr2: '',
      current_pw: '00000000',
      new_pw: '',
      new_pw2: '',
      Bucket: process.env.VUE_APP_AWSBUCKETNAME,
      region: process.env.VUE_APP_AWSBUCKETREGION,
      IdentityPoolId: process.env.VUE_APP_AWSPOOLID,
      AWSURL: process.env.VUE_APP_AWSURL,
      default_image: '/assets/img/profile_default.svg',
      profile_path: '',
      // params: {
      //     token: '123456798',
      //     name: 'avatar'
      // },
      // headers: {
      //     smail: '*_~'
      // },
      isEditPhone: false,
      disabled_phone: false,
      show_area: false,
      contact: '',
      phone_certifi_send_chk: false,
      phone_certifi_chk: null,
      certifi_phone_num: '',
      service: 'SKT',
      secu_count_num: 180,
    };
  },
  mounted() {
    // 임시 데이터
    // this.info.avatar = require('@/assets/img/profile_default.svg');
    // this.info.id = 'qqlppx@gmail.com';
    // this.info.name = '백폴더0411';
    // this.info.address = '대전광역시 유성구 지족로 328번길 86-16';
    // this.info.phone = '010-1234-5678'

    if (this.profile_path == '') {
      this.profile_path = require('@/assets/img/profile_default.svg');
    }
    this.GetProfileInfo();
  },
  methods: {
    NonPhoneAuth() {
      // this.$confirm("휴대폰 인증을 진행하시겠습니까?")
      // this.$refs.phonePopup.pp = true;
    },
    SearchAddress() {
      var element_layer = document.getElementById('layer');
      new daum.Postcode({
        oncomplete: (data) => {
          var addr = ''; // 주소 변수
          var extraAddr = ''; // 참고항목 변수
          if (data.userSelectedType === 'R') {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            addr = data.jibunAddress;
          }
          if (data.userSelectedType === 'R') {
            if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }

            if (data.buildingName !== '' && data.apartment === 'Y') {
              extraAddr +=
                extraAddr !== '' ? ', ' + data.buildingName : data.buildingName;
            }

            if (extraAddr !== '') {
              extraAddr = ' (' + extraAddr + ')';
            }
            this.addr2 = extraAddr;
          } else {
            this.addr2 = '';
          }

          this.addr1 = addr;
          this.addr2 = '';
          this.show_area = false;
        },
        width: '100%',
        height: '100%',
        maxSuggestItems: 5,
      }).embed(element_layer);

      this.show_area = true;
      // this.initLayerPosition();
    },
    closeDaumPostcode() {
      this.show_area = false;
    },
    CheckShowAreaClass() {
      if (this.show_area == true) {
        return 'mb-3';
      } else {
        return 'mb-0';
      }
    },
    ShowArea() {
      const show_area = this.show_area;

      if (show_area == true) {
        return 'height:400px';
      } else {
        return 'height:0px';
      }
    },
    LogOut() {
      this.$store.dispatch('SETLOGOUT').then(() => {
        this.$router.push({ path: '/signin' });
      });
    },
    ImageChange(e) {
      const files = e.target.files;

      if (files != null) {
        const file = files[0];
        const date = new Date();
        const timestamp = date.getTime();
        let fileName = 'profile_' + timestamp + '_' + file.name;
        fileName = urlencode(fileName);
        AWS.config.update({
          region: this.region,
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: this.IdentityPoolId,
          }),
        });
        var s3 = new AWS.S3({
          apiVersion: '2006-03-01',
          params: { Bucket: this.Bucket },
        });
        s3.upload(
          {
            Key: fileName,
            Body: file,
            ACL: 'public-read',
            ContentType: `${file.type}`,
          },
          (err) => {
            if (err == null) {
              this.profile_path = this.AWSURL + fileName;
              this.ChangeProfileImage();
            } else {
              return false;
            }
          }
        );
      }
    },

    GetProfileInfo() {
      const body = {};
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      // this.loading = true;
      this.$http.post('/front/mypage/GetProfileInfo', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);
            this.name = body.info.name;
            this.email = body.info.email;
            this.phone = body.info.phone;
            this.phone_auth = body.info.phone_auth;
            this.addr1 = body.info.addr1;
            this.addr2 = body.info.addr2;
            if (body.info.profile_path != '') {
              this.profile_path = body.info.profile_path;
            }
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              this.$router.push({ path: '/signin' });
            });
          }
        }
      });
    },
    ChangeProfileImage() {
      const profile_path = this.profile_path;

      const body = { profile_path };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.$http.post('/front/mypage/ChangeProfileImage', { req });
    },
    ChangeProfile() {
      const name = this.name;
      const phone = this.phone;
      const addr1 = this.addr1;
      const addr2 = this.addr2;

      const body = { name, phone, addr1, addr2 };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http.post('/front/mypage/ChangeProfile', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            this.$alert(
              `<i class="fal fa-check-circle fa-2x mb-2 text-success"></i><p class="small mt-1">정보를 수정하였습니다.</p>`
            ).then(() => {
              this.$store.dispatch('SETNAME', name).then(() => {
                this.$router.go();
              });
            });
          } else if (res.data.code == '9999') {
            this.$store.dispatch('SETLOGOUT').then(() => {
              this.$router.push({ path: '/signin' });
            });
          }
        }
      });
    },
    SecuCountFn: function () {
      this.secu_count_int = setInterval(
        function () {
          if (this.secu_count_num > 0) {
            this.secu_count_num--;
          } else {
            // 인터벌 종료
            clearInterval(this.secu_count_int);
          }
        }.bind(this),
        1000
      );
    },
    CheckDuplPhone() {
      const phone = this.contact;

      const body = { phone };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http.post('/front/sign/SendSms', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            this.phone_certifi_send_chk = true;
            this.disabled_phone = true;
            this.SecuCountFn();
            this.secu_count_num = 180;
          } else if (res.data.code == '100') {
            this.$alert('기존에 가입된 연락처 입니다.');
            this.phone_certifi_send_chk = false;
            this.disabled_phone = false;
          }
        }
      });
    },
    CertificatePhone() {
      if (this.secu_count_num <= 0) {
        this.$alert('인증시간을 초과했습니다');
        this.phone_certifi_chk = false;
        this.disabled_phone = false;
        return false;
      }

      const phone = this.contact;
      const code = this.certifi_phone_num;

      const body = { phone, code };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.loading = true;
      this.$http.post('/front/sign/ConfirmSMS', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            this.phone_certifi_chk = true;
            this.phone_certifi_send_chk = false;
            this.phone = this.contact;
            this.$alert('휴대폰 인증되었습니다.').then(() => {
              this.ChangeProfile();
            });
          } else if (res.data.code == '100') {
            this.phone_certifi_send_chk = false;
            this.$alert('기존에 가입된 연락처 입니다.');
            this.phone_certifi_send_chk = false;
            this.disabled_phone = false;
          } else if (res.data.code == '300') {
            this.phone_certifi_chk = false;
            this.$alert('인증코드를 확인해주세요.');
          }
        }
      });
    },
    ShowTime: function (x) {
      var min = Math.floor(x / 60);
      var sec = x % 60;

      if (sec.toString().length == 1) sec = '0' + sec;

      if (x >= 60) {
        return min + ':' + sec;
      } else {
        return '0:' + sec;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#profile {
  min-height: calc(100vh - 70px) !important;
}
.avatar > div {
  width: 120px;
  height: 120px;
  background-size: cover;
}
</style>
