<template>
  <div id="history" class="bg-gray-relative-100">
    <div class="pb-3">
      <SubHeader title="결제 요청 상세"></SubHeader>
    </div>
    <div class="px-3">
      <div class="p-3 mb-3 bg-body rounded-4 shadow-400">
        <div class="fs-px-15 lh-lg fw-bold flex-between-center">
          <p class="" v-if="type == 'D'">
            {{ addDay(date) }} {{ confirm_time }}
          </p>
          <p class="" v-if="type == 'R'">
            {{ addDay(date) }}
            <span v-if="status != 'H'">
              {{ confirm_time }}
            </span>
            <span v-if="status == 'H'">{{ start_time }} ~ {{ end_time }} </span>
          </p>
        </div>
        <div class="flex-between-center mb-2 flex-wrap fs-px-14">
          <span class="badge badge-custom-1">{{ memo }} 코스</span>
          <span class="">{{ proxy_name }} 외 {{ people - 1 }}명</span>
        </div>

        <div class="w-100 flex-between-center">
          <div
            class="bg-img w-px-70 h-px-70 rounded-4 shadow flex-shrink-0"
            :style="{ backgroundImage: 'url(' + l_image_path + ')' }"
          ></div>
          <div class="txt_box small w-80 ps-3">
            <p
              class="h6 fw-bold lh-lg"
              :class="{
                'text-success': status === 'Y',
                'text-primary': status === 'P',
                'text-gray-500': status === 'H',
                'text-danger': status === 'C',
                'text-warning': status === 'CP',
              }"
            >
              {{
                status === 'Y'
                  ? '결제 완료'
                  : status === 'H'
                  ? '결제 대기'
                  : status === 'P'
                  ? '결제 요청'
                  : status === 'CP'
                  ? '취소 요청'
                  : '취소 완료'
              }}
            </p>
            <p class="h6 fw-bold mb-1">{{ l_name }}</p>
            <p class="lh-sm text-gray-relative-600">
              <i class="far fa-map-marker-alt me-1"></i>{{ l_address }}
            </p>
          </div>
        </div>
        <div class="flex-between-center pt-3 fs-px-14" v-if="status === 'C'">
          <small>결제 취소 일시</small>
          <small class="small">{{ addDay(update_time) }}</small>
        </div>
        <hr />
        <div class="text-start fs-px-15 mb-3">
          <div class="flex-between-center">
            <b>결제 요청 금액</b>
            <p @click="detailOpen = !detailOpen">
              <b class="text-success" v-if="status !== 'C'">
                {{ CheckPrice(price) }} 원
              </b>
              <b class="text-success" v-if="status === 'C'">
                -{{ CheckPrice(price) }} 원
              </b>
              <i
                class="fas fa-sm text-gray-relative-500"
                :class="{
                  'fa-chevron-down': !detailOpen,
                  'fa-chevron-up': detailOpen,
                }"
              ></i>
            </p>
          </div>
          <div class="fs-px-13 flex-between-center" v-show="detailOpen">
            <span class="ps-2">
              └ 그린피 <span v-show="cart == 'Y'">+ 카트비</span>
            </span>
            <p class="pe-3" v-if="status !== 'C'">{{ CheckPrice(price) }} 원</p>
            <p class="pe-3" v-if="status === 'C'">
              -{{ CheckPrice(price) }} 원
            </p>
          </div>
        </div>

        <div class="text-start fs-px-15 mb-3" v-show="other == 'Y'">
          <div class="flex-between-center">
            <b>기타 요청 사항</b>
          </div>
          <div class="fs-px-13 flex-between-center" v-show="detailOpen">
            <span class="ps-2">
              <span>{{ other_memo }}</span>
            </span>
          </div>
        </div>

        <div
          class="text-start fs-px-15 mb-3"
          v-if="status == 'C' || status == 'CP'"
        >
          <div class="flex-between-center">
            <b>취소 사유</b>
          </div>
          <div class="fs-px-13 flex-between-center" v-show="detailOpen">
            <span class="ps-2">
              <span>{{ cancel_memo }}</span>
            </span>
          </div>
        </div>

        <div class="text-start fs-px-15 mb-3" v-if="status == 'CP'">
          <div class="flex-between-center">
            <b v-if="status == 'CP'">취소 요청 일시</b>
          </div>
          <div class="fs-px-13 flex-between-center" v-show="detailOpen">
            <span class="ps-2">
              <span>{{ addDay(update_time) }}</span>
            </span>
          </div>
        </div>
        <!-- <button class="btn thick btn-main text-white w-100 rounded-3" @click="CancelPayment()" v-if="status=='Y'">결제 요청 취소</button> -->

        <button
          v-if="status === 'P'"
          class="btn thick btn-main text-white w-100 rounded-3"
          @click="GoToPayment(item)"
        >
          결제 진행
        </button>
        <button
          class="btn thick btn-main text-white w-100 rounded-3 mt-3"
          @click="CancelPayment(item)"
          v-if="status != 'C' && status != 'CP'"
        >
          취소
        </button>
        <button
          v-if="status == 'C'"
          class="btn thick btn-outline-main text-main w-100 rounded-3"
          @click="OpenDetail"
        >
          다시 결제 요청하기
        </button>
      </div>
    </div>

    <CancelPopup ref="CancelPopup" :status="status" />
    <ReservationPopup ref="ReservationPopup" />
  </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue';
import CancelPopup from '@/components/popup/Cancel.vue';
import ReservationPopup from '@/components/popup/Reservation.vue';
import { format } from '@/mixins';

const CryptoJS = require('crypto-js');
export default {
  components: {
    SubHeader,
    CancelPopup,
    ReservationPopup,
  },
  mixins: [format],
  data() {
    return {
      code: this.$route.params.code,
      cartChk: true,
      detailOpen: true,
      list: [],
      item: '',
      type: '',
      confirm_time: '',
      start_time: '',
      end_time: '',
      date: '',
      memo: '',
      type: '',
      status: '',
      people: '',
      cart: '',
      other: '',
      other_memo: '',
      update_time: '',
      l_name: '',
      l_address: '',
      l_image_path: '',
      price: '',
      proxy: '',
      proxy_name: '',
      cancel_able: '',
      payment_name: '',
      cancel_memo: '',
    };
  },
  // computed: {
  //   dateFormat() {
  //     return moment(this.date).format('YYYY MMMM Do dddd');
  //   },
  // },
  mounted() {
    this.GetReservationList();
  },
  methods: {
    CheckPrice(price) {
      const amount = price * 1;
      return amount.toLocaleString();
    },
    GetReservationList() {
      const code = this.code;
      const body = { code };
      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      // this.loading = true;
      this.$http
        .post('/front/reservation/GetReservationInfo', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const e_body = res.data.body;
              const bytes = CryptoJS.AES.decrypt(
                e_body,
                process.env.VUE_APP_SECRET_TOKEN
              );
              const d_res = bytes.toString(CryptoJS.enc.Utf8);
              const body = JSON.parse(d_res);

              this.type = body.info.type;
              this.date = body.info.date;
              this.people = body.info.people;
              this.memo = body.info.memo;
              this.status = body.info.status;
              this.price = body.info.price;
              this.update_time = body.info.update;
              this.cancel_able = body.info.cancel_able;
              this.cart = body.info.cart;
              this.other = body.info.other;
              this.proxy = body.info.proxy;
              this.other_memo = body.info.other_memo;
              this.cancel_memo = body.info.cancel_memo;
              this.confirm_time = body.info.confirm_time;
              this.proxy_name = body.proxy_name;
              this.payment_name = body.payment_name;

              this.l_name = body.l_info.name;
              this.l_address = body.l_info.address;
              this.l_image_path = body.l_info.image_path;
              this.item = body.l_info;
              // this.list = body.info;
              // 임시로 데이터 넣음
              // this.item = this.list[0];
            } else if (res.data.code == '9999') {
              this.$store.dispatch('SETLOGOUT').then(() => {
                this.$router.push({ path: '/signin' });
              });
            }
          }
        });
    },
    GoToPayment() {
      const status = this.status;
      const code = this.code;
      if (status == 'P') {
        this.$alert('결제 진행하시겠습니까?').then(() => {
          this.$router.push({ path: `/payment/${code}` });
        });
      } else if (status == 'Y') {
        this.$router.push({ path: `/receipt/${code}` });
      } else if (status == 'C') {
        this.$router.push({ path: `/receipt/${code}` });
      }
    },
    CancelPayment() {
      // 임시
      const name = this.l_name;
      const date = this.date;
      const time = this.confirm_time;
      const memo = this.memo;
      const cart = this.cart;
      const price = this.price;
      const people = this.people;
      const other = this.other;
      const other_memo = this.other_memo;
      const payment_name = this.payment_name;
      const item = {
        name,
        date,
        time,
        memo,
        cart,
        price,
        people,
        other,
        other_memo,
        payment_name,
      };
      this.$refs.CancelPopup.item = item;
      this.$refs.CancelPopup.pp = true;
    },
    OpenDetail() {
      this.$refs.ReservationPopup.item = this.item;
      this.$refs.ReservationPopup.pp = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#history {
  min-height: calc(100vh - 70px) !important;
  padding-bottom: 20px;
}
.badge-custom-1 {
  border: 1px solid #52c19c;
  color: #52c19c;
  line-height: 12px;
  font-weight: 600;
  background-color: #68d4ab13;
}
.gradient-danger {
  background: linear-gradient(to bottom, #fc6e51, #e9573f);
}
.gradient-success {
  background: linear-gradient(to bottom, #a0d468, #8cc152);
}
</style>
